import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import settings from "./settings.json";
import { authenticationService } from './authentication.service';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    progressBar: {
        width: '100%',
        textAlign: 'center'
    }

}));
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [username, setUsername] = useState();
    const changeUsernameHandler = (event) => {
        setUsername(event.target.value);
    };

    const [password, setPassword] = useState();
    const changePasswordHandler = (event) => {
        setPassword(event.target.value);
    };

    const [triggerSubmit, setTriggerSubmit] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);

    const handleSubmission = () => {

        setTriggerSubmit(true);
        if (!(username && password)) {
            return;
        }

        setInProgress(true);
        authenticationService.login(username, password)
            .then(
                user => {
                    window.location = '/Create';
                    setInProgress(false);
                },
                error => {
                    setInProgress(false);
                    setOpen(true);
                }
            );

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            error={!username && triggerSubmit ? true : false}
                            fullWidth
                            id="username"
                            label="username"
                            name="username"
                            validators={['required']}
                            onChange={changeUsernameHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            error={!password && triggerSubmit ? true : false}
                            fullWidth
                            id="password"
                            label="password"
                            name="password"
                            type="password"
                            validators={['required']}
                            onChange={changePasswordHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {inProgress && <div className={classes.progressBar}>
                            <CircularProgress />
                        </div>}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmission}
                            className={classes.submit}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Login Failed!
                </Alert>
            </Snackbar>
        </Container>


    );
}

export default Login;
