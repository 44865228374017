import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import settings from "./settings.json";
import { authenticationService } from './authentication.service';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    progressBar: {
        width: '100%',
        textAlign: 'center'
    }

}));
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Create() {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        console.log(selectedFile);
    };

    const [description, setDescription] = useState();
    const changeDescriptionHandler = (event) => {
        setDescription(event.target.value);
    };

    const [supplier, setSupplier] = useState();
    const changeSupplierHandler = (event) => {
        setSupplier(event.target.value);
    };

    const [receiptNumber, setReceiptNumber] = useState();
    const changeReceiptNumberHandler = (event) => {
        setReceiptNumber(event.target.value);
    };

    const [category, setCategory] = useState();
    const changeCategoryHandler = (event) => {
        setCategory(event.target.value);
    };

    const [amount, setAmount] = useState();
    const changeAmountHandler = (event) => {
        setAmount(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [selectedDate, setSelectedDate] = React.useState();

    const handleDateChange = (date) => {
        const dateControl = document.querySelector('input[type="date"]');
        setSelectedDate(dateControl.value);
    };

    const [triggerSubmit, setTriggerSubmit] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);

    const handleSubmission = () => {

        setTriggerSubmit(true);
        if (!(category && description && amount && selectedFile)) {
            return;
        }

        setInProgress(true);
        const transaction = {
            categoryId: category,
            description: description,
            amount: amount,
            receiptDate: selectedDate || new Date().toISOString().slice(0, 10),
            receiptUrl: category + '\\' + selectedFile.name,
            supplier: supplier,
            receiptNumber: receiptNumber
        };

        const currentUser = authenticationService.currentUserValue;
        if (!currentUser.token) {
            window.location = '/Login';
        }

        axios.post(settings.WEB_API_URL + `/api/transaction`, transaction, {
            headers: {
                'Authorization': `Bearer ${currentUser.token}`
            }
        })
            .then(res => {
                if (res.data.receiptUrl) {
                    const formData = new FormData();
                    formData.append('file', selectedFile, res.data.receiptUrl);

                    fetch(settings.WEB_API_URL +
                        '/api/UploadFile',
                        {
                            method: 'POST',
                            body: formData,
                        }
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            console.log('Success:', result);
                            setInProgress(false);
                            setOpen(true);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            });


    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="select-outlined-label">Category</InputLabel>
                        <Select
                            labelId="select-outlined-label"
                            id="Category"
                            required
                            value={category}
                            error={!category && triggerSubmit ? true : false}
                            onChange={changeCategoryHandler}
                            label="Category"
                        >
                            <MenuItem value={'1'}>1/71 Browns Bay Rd</MenuItem>
                            <MenuItem value={'2'}>2/71 Browns Bay Rd</MenuItem>
                            <MenuItem value={'3'}>HZ IT ltd</MenuItem>
                        </Select>
                    </FormControl>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            error={!description && triggerSubmit ? true : false}
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            validators={['required']}
                            onChange={changeDescriptionHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="supplier"
                            label="Supplier"
                            name="supplier"
                            onChange={changeSupplierHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="receiptNumber"
                            label="Receipt number"
                            name="receiptNumber"
                            onChange={changeReceiptNumberHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            error={!amount && triggerSubmit ? true : false}
                            id="amount"
                            label="Amount"
                            name="amount"
                            validators={['required']}
                            onChange={changeAmountHandler}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="date"
                            label="Date Created"
                            type="date"
                            defaultValue={new Date().toISOString().slice(0, 10)}
                            onChange={handleDateChange}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            error={!isSelected && triggerSubmit}
                            type="file"
                            id="file"
                            name="file"
                            validators={['required']}
                            onChange={changeHandler}
                        />
                        {/* <input type="file" name="file" onChange={changeHandler} /> */}
                        {isSelected ? (
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Filetype: {selectedFile.type}</p>
                                <p>Size in bytes: {selectedFile.size}</p>
                            </div>
                        ) : (
                            <p>Select a file to show details</p>
                        )}

                    </Grid>

                    <Grid item xs={12}>
                        {inProgress && <div className={classes.progressBar}>
                            <CircularProgress />
                        </div>}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmission}
                            className={classes.submit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>


            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Successful!
                </Alert>
            </Snackbar>
        </Container>


    );
}

export default Create;
