import React, { useState, useEffect } from 'react';
import {
    DataGrid, GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import settings from "./settings.json";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { authenticationService } from './authentication.service';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const columns = [
    { field: 'categoryName', headerName: 'Category', width: 160 },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'receiptDate', headerName: 'Date', type: 'date', width: 130 },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        width: 130,
    },
    { field: 'supplier', headerName: 'Supplier', width: 140 },
    { field: 'receiptNumber', headerName: 'Receipt Number', width: 140 },
    {
        field: "Receipt",
        headerName: "",
        width: 150,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            const azureStorageUrl = settings.AZURESTORAGE_URL;
            const url = azureStorageUrl + params.row.receiptUrl;
            return <Link href={url}>View Receipt</Link>;
        }
    }
];
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginRight: '20px',
        marginTop: '10px',
        minWidth: 120,
        width: '45%'

    },
    progressBar: {
        width: '100%',
        textAlign: 'center'
    }

}));

function TransactionList() {
    const classes = useStyles();
    const [rows, setRows] = useState([]);

    const currentUser = authenticationService.currentUserValue;
    if (!currentUser.token) {
        window.location = '/Login';
    }

    useEffect(() => {
        axios.get(settings.WEB_API_URL + `/api/transaction`, {
            headers: {
                'Authorization': `Bearer ${currentUser.token}`
            }
        })
            .then(res => {
                if (res.data) {
                    setResultRows(res.data);
                    setRows(res.data);
                }
            })
            .catch(err => {
                window.location = '/Login';
            });
    }, []);

    const [selectedFromDate, setSelectedFromDate] = React.useState(new Date().toISOString().slice(0, 10));

    const handledSelectedFromDate = (date) => {
        const dateControl = document.querySelector('#from_date');
        setSelectedFromDate(dateControl.value);
    };

    const [selectedToDate, setSelectedToDate] = React.useState(new Date().toISOString().slice(0, 10));

    const handleSelectedToDate = (date) => {
        const dateControl = document.querySelector('#to_date');
        setSelectedToDate(dateControl.value);
    };

    const [resultRows, setResultRows] = useState([]);

    const handleSearch = () => {
        let result;
        if (category > 0) {
            result = resultRows.filter(item => item.receiptDate >= selectedFromDate && item.receiptDate <= selectedToDate && item.categoryId == category);
        }
        else {
            result = resultRows.filter(item => item.receiptDate >= selectedFromDate && item.receiptDate <= selectedToDate);
        }

        setRows(result);
    };

    const [category, setCategory] = useState(0);

    const changeCategoryHandler = (event) => {
        setCategory(event.target.value);
    };



    return (
        <div style={{ marginTop: '10px' }}>
            <Container component="main">
                <CssBaseline />
                <div>
                    <TextField
                        id="from_date"
                        label="From Date"
                        style={{ width: '45%' }}
                        type="date"
                        defaultValue={new Date().toISOString().slice(0, 10)}
                        onChange={handledSelectedFromDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ marginRight: '20px' }}
                    />
                    <TextField
                        id="to_date"
                        label="To Date"
                        style={{ width: '45%', float: 'right' }}
                        type="date"
                        defaultValue={new Date().toISOString().slice(0, 10)}
                        onChange={handleSelectedToDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="select-outlined-label">Category</InputLabel>
                        <Select
                            labelId="select-outlined-label"
                            id="Category"
                            value={category}
                            onChange={changeCategoryHandler}
                            label="Category"
                        >
                            <MenuItem value={'0'}>All</MenuItem>
                            <MenuItem value={'1'}>1/71 Browns Bay Rd</MenuItem>
                            <MenuItem value={'2'}>2/71 Browns Bay Rd</MenuItem>
                            <MenuItem value={'3'}>HZ IT ltd</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        style={{ verticalAlign: 'bottom', marginBottom: '8px', width: '45%', float: 'right', top: '20px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </div>
            </Container>
            <div style={{ height: 600, width: '100%' }}>

                <DataGrid rows={rows} columns={columns} pageSize={10} checkboxSelection components={{
                    Toolbar: CustomToolbar,
                }} />
            </div>
        </div>
    );
}

export default TransactionList;
