import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Create from './Create';
import TransactionList from './TransactionList';
import Login from './Login';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { authenticationService } from './authentication.service';
import { createBrowserHistory } from "history";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));
const history = createBrowserHistory();
function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const currentUser = authenticationService.currentUserValue;
  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    history.push('/login');
  }

  return (

    <Router history={history}>

      <Switch>
        <Route path="/" exact component={Create} />
        <Route path="/Login" component={Login} />
        <Route path="/Create" component={Create} />
        <Route path="/TransactionList" component={TransactionList} />
      </Switch>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.form}
      >
        <BottomNavigationAction label="Create Transaction" component={Link}
          to="/Create" />
        <BottomNavigationAction label="Transaction List" component={Link}
          to="/TransactionList" />
      </BottomNavigation>
    </Router>

  );
}

export default App;
